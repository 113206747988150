<template>
    <el-collapse v-model="collapse">
        <el-collapse-item name="1">
            <template #title>
                <Heading>Händelser ({{ events ? events.length : 0 }})</Heading>
            </template>
            <el-timeline>
                <el-timeline-item v-for="(event, index) in events" :key="index" :type="getType(event.type)" :timestamp="formatDate(event.eventTime)">
                    <el-card>
                        <h4 class="font-bold">{{ getTypeName(event.type) }} - {{ event.byUser }}</h4>
                        <p>{{ event.comment }}</p>
                    </el-card>
                </el-timeline-item>
            </el-timeline>
        </el-collapse-item>
    </el-collapse>
</template>

<script>
import moment from "moment";

export default {
    props: ["events"],
    data() {
        return {
            collapse: "0",
        };
    },
    components: {
        Heading: () => import(/* webpackChunkName: "DateInputField" */ "@/components/content/Heading.vue"),
    },
    methods: {
        formatDate(date) {
            return date
                ? moment
                      .utc(date)
                      .local()
                      .format("YYYY-MM-DD")
                : "";
        },
        getTypeName(eventType) {
            switch (eventType) {
                case 0:
                    return "Utkast";
                case 1:
                    return "Registrerad";
                case 2:
                    return "Attesterad";
                case 3:
                    return "Betald";
                case 4:
                    return "Nekad";
                case 5:
                    return "Komplettering begärd";
                case 6:
                    return "Kompletterad";
                case 7:
                    return "Kopierad";
            }
        },
        getType(eventType) {
            switch (eventType) {
                case 0:
                    return "info";
                case 1:
                    return "info";
                case 2:
                    return "success";
                case 3:
                    return "success";
                case 4:
                    return "success";
                case 5:
                    return "danger";
                case 6:
                    return "warning";
                case 7:
                    return "warning";
            }
        },
    },
};
</script>

<style class="postcss">
.el-collapse-item__wrap,
.el-collapse-item__header {
    background-color: transparent !important;
}
</style>
